import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";

function CardView(props) {
    const cardData = JSON.parse(props.carddata);
    let cardWin = {};


    let fromH = parseInt(props.hagralanum);
    let toH = fromH + parseInt(props.hagrlot) - 1;
    if (props.cardWin !== undefined && props.cardWin !== "") {
        try {
            cardWin = JSON.parse(props.cardWin);
            // JSON parsed successfully
            // You can proceed with using the parsed data
        } catch (error) {
            cardWin = {};
            // An error occurred while parsing JSON
            // Handle the error (log it, display a message, etc.)
            console.error('Card id: ' + props.uid + 'Error parsing JSON:', error);
            // Optionally, you can provide a fallback value or handle the error in another way
        }
    }
    const GetItemCname = (name, val) => {
        let retv = name;
        if (val == undefined || val.length == 0) {
            return retv;
        }
        if (val) {
            retv += " active";
        }

        return retv;
    }
    const GetCname = (index) => {

        if (index == 0) {
            return "card-T card0-f primary-B card-R";
        }
        if (index == 1) {
            return "card-T card1-O primary-B card-R";
        }
        if (index == 2) {
            return "card-T card2-u primary-B card-R";
        }
        if (index == 3) {
            return "card-T card3-C primary-B card-R";
        }
    }
    const SumWin = () => {
        if (!cardWin) return 0;
        const cardWinArray = Object.values(cardWin);
        let totalWin = cardWinArray.map(item => item.win).reduce((acc, cur) => acc + cur, 0);
        return totalWin;
    }
    const formatDate = (datetime) => {
        const dateObj = new Date(datetime);
        const year = dateObj.getFullYear().toString().slice(-2); // Get last two digits of the year
        const month = ('0' + (dateObj.getMonth() + 1)).slice(-2); // Add leading zero for single-digit months
        const day = ('0' + dateObj.getDate()).slice(-2); // Add leading zero for single-digit days
        const hours = ('0' + dateObj.getHours()).slice(-2); // Add leading zero for single-digit hours
        const minutes = ('0' + dateObj.getMinutes()).slice(-2); // Add leading zero for single-digit minutes

        return `${day}/${month}/${year} ${hours}:${minutes}`;
    };
    const cardStyle1 = cardWin?.[props.curenthagrala]?.[0] === false ? { backgroundColor: 'coral' } : cardWin?.[props.curenthagrala]?.[0] === true ? { backgroundColor: 'green' } : {};
    const cardStyle2 = cardWin?.[props.curenthagrala]?.[1] === false ? { backgroundColor: 'coral' } : cardWin?.[props.curenthagrala]?.[1] === true ? { backgroundColor: 'green' } : {};
    const cardStyle3 = cardWin?.[props.curenthagrala]?.[2] === false ? { backgroundColor: 'coral' } : cardWin?.[props.curenthagrala]?.[2] === true ? { backgroundColor: 'green' } : {};
    const cardStyle4 = cardWin?.[props.curenthagrala]?.[3] === false ? { backgroundColor: 'coral' } : cardWin?.[props.curenthagrala]?.[3] === true ? { backgroundColor: 'green' } : {};
    const isChk = cardWin?.[props.curenthagrala] !== undefined;

    const IsCardUsedBool = (idex) => {
        let from = parseInt(props.hagralanum);
        let to = parseInt(props.hagralanum) + parseInt(props.hagrlot) - 1;
        let cureent = parseInt(props.curenthagrala);
        if (from <= cureent && cureent <= to) {
            if (cardWin?.[cureent]) {
                return true;
            } else {
                return false;
            }
        } else {
            if (cardWin?.[from]) {
                return true;
            } else {
                return false;
            }
        }
    }
    const totalWin = SumWin(props.cardWin);

    const getShitsStyle = (index, cval) => {
        let from = parseInt(props.hagralanum);
        let to = parseInt(props.hagralanum) + parseInt(props.hagrlot) - 1;
        let cureent = parseInt(props.curenthagrala);
        if (from <= cureent && cureent <= to) {
            if (cardWin?.[cureent]?.[index] == false) {
                return { backgroundColor: 'coral' }

            } else if (cardWin?.[cureent]?.[index] == true) {
                if (cardWin?.[cureent]?.result[index] == cval) {
                    return { backgroundColor: 'green' };
                } else {
                    return { backgroundColor: 'coral' }
                }
            } else {
                return {};
            }
        }
        else {
            if (cardWin?.[from]?.[index] == false) {
                return { backgroundColor: 'coral' }

            } else if (cardWin?.[from]?.[index] == true) {
                if (cardWin?.[from]?.result[index] == cval) {
                    return { backgroundColor: 'green' };
                } else {
                    return { backgroundColor: 'coral' }
                }
            } else {
                return {};
            }
        }

    }


    const IsCardUsed = (index) => {
        let from = parseInt(props.hagralanum);
        let to = parseInt(props.hagralanum) + parseInt(props.hagrlot) - 1;
        let cureent = parseInt(props.curenthagrala);
        if (from <= cureent && cureent <= to) {
            if (cardWin?.[cureent]) {
                return cardWin?.[cureent]?.[index] === false ? { backgroundColor: 'coral' } : { backgroundColor: 'green' }
            } else {
                return {};
            }
        } else {
            if (cardWin?.[from]) {
                return cardWin?.[from]?.[index] === false ? { backgroundColor: 'coral' } : { backgroundColor: 'green' }
            } else {
                return {};
            }
        }
    }
    const ShowTakeButton = () => {
        let from = parseInt(props.hagralanum);
        let to = parseInt(props.hagralanum) + parseInt(props.hagrlot) - 1;
        let cureent = parseInt(props.curenthagrala);
        if (from <= cureent && cureent <= to) {
            if (cardWin?.[cureent]) {
                let win = cardWin?.[cureent].win > 0;
                let used = cardWin?.[cureent].used > 0;
                return win && !used;
            } else {
                return false;
            }
        } else {
            if (cardWin?.[from]) {
                let win = cardWin?.[from].win > 0;
                let used = cardWin?.[from].used > 0;
                return false;//win && !used;
            } else {
                return false;
            }
        }
    }
    const ShowCloseButton = () => {
        //if card.any.used
        if (props.prize == totalWin)
            return true;
        else
            return false;

    }
    const showTb = ShowTakeButton();
    const showCloseTb = ShowCloseButton();
    return (
        <Card className="project-card-view" onClick={() => props.onSelect(props.hagralanum, props.hagrlot)}>
            <Card.Body>
                <Card.Title>
                    {props.title}
                    {props.card_game_type == 5 && <br />}
                    {props.card_game_type == 5 && " רב צאנס "}
                    {totalWin > 0 && ` ${totalWin} ₪`}</Card.Title>
                <div className="general-n" >
                    <div className="general-i">

                    </div>
                    <div className="general-O">
                        <div className="content-E">
                            <div className="header-C" >
                                <span className="vtimmv">{formatDate(props.datet)}</span>
                                <div className="wwactions-j">
                                    <span className="hnum">{props.hagralanum}</span>
                                </div>
                            </div>
                            <div className="sub-header-C" >
                                {parseInt(props.hagrlot) !== 1 && (<span className="vtotatalto" style={{ float: "left" }}> {toH} </span>)}
                                <span className="vprice">מחיר: {props.price}  </span>

                                <br />
                                <span className="vtotatal">הגרלות: {props.hagrlot} </span>

                                <br />

                                <span className="vtotatal"  >סה'כ: {props.total}</span>
                                {IsCardUsedBool &&
                                    <span className="vtotatalwin" style={{ float: "left" }}>זכייה: {cardWin?.[props.curenthagrala]?.win} </span>
                                }
                                <div className="wwactions-j">

                                </div>
                            </div>

                            {props.isshita && (
                                <div className="shita-container">
                                    {cardData?.map((card, index) => (
                                        <div className={`shita-row-P row-${index}`} key={index} >
                                            <div className="shits-cards-k">
                                                <div className={GetItemCname("item-c", card?.[0])} style={getShitsStyle(index, card?.[0])}>
                                                    <div className={GetCname(index)}></div>
                                                    <div className="value-L"> {card?.[0]}</div>
                                                </div>
                                                <div className={GetItemCname("item-c", card?.[1])} style={getShitsStyle(index, card?.[1])}>
                                                    <div className={GetCname(index)}></div>
                                                    <div className="value-L"> {card?.[1]}</div>
                                                </div>
                                                <div className={GetItemCname("item-c", card?.[2])} style={getShitsStyle(index, card?.[2])}>
                                                    <div className={GetCname(index)}></div>
                                                    <div className="value-L"> {card?.[2]}</div>
                                                </div>
                                                <div className={GetItemCname("item-c", card?.[3])} style={getShitsStyle(index, card?.[3])}>
                                                    <div className={GetCname(index)}></div>
                                                    <div className="value-L"> {card?.[3]}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {!props.isshita && //props.curenthagrala == 

                                <div className="row-P row-0" >
                                    <div className="cards-k">
                                        <div className={GetItemCname("item-c", cardData?.[0])} style={IsCardUsed(0)}>
                                            <div className="card-T card0-f primary-B card-R"></div>
                                            <div className="value-L"> {cardData?.[0]}</div>
                                        </div>
                                        <div className={GetItemCname("item-c", cardData?.[1])} style={IsCardUsed(1)}>
                                            <div className="card-T card1-O primary-B card-R"></div>
                                            <div className="value-L"> {cardData?.[1]}</div>
                                        </div>
                                        <div className={GetItemCname("item-c", cardData?.[2])} style={IsCardUsed(2)}>
                                            <div className="card-T card2-u primary-B card-R"></div>
                                            <div className="value-L"> {cardData?.[2]}</div>
                                        </div>
                                        <div className={GetItemCname("item-c", cardData?.[3])} style={IsCardUsed(3)}>
                                            <div className="card-T card3-C primary-B card-R"></div>
                                            <div className="value-L"> {cardData?.[3]}</div>
                                        </div>
                                    </div>
                                </div>

                            }

                        </div>
                    </div>
                </div>
                {"\n"}
                {"\n"}

                {/* If the component contains Demo link and if it's not a Blog then, it will render the below component  */}
                {props.isChek && (
                    <div className="tested-label" >נבדק סה"כ <br /> {`${totalWin} ₪`}
                    </div>

                )}
                {props.isChek && props.prize == totalWin && (
                    <div className="tested-label-close" >
                        <button type="button" onClick={() => props.hidec(props.uid)} className="btn btn-secondary close-btn" style={{ marginLeft: '10px',backgroundColor:'lightgray' }}>X</button>
                    </div>

                )}
                {!cardWin?.[props.curenthagrala] && props.curenthagrala >= fromH && props.curenthagrala <= toH ? (
                    <Button
                        variant="primary"
                        onClick={() => props.chkclike(props.uid, props.curenthagrala)}
                        style={{ marginLeft: "10px" }}
                    >
                        <CgWebsite /> &nbsp;
                        בדוק כרטיס
                    </Button>
                ) :
                    (
                        <Button
                            variant="secondary"
                            style={{ marginLeft: "10px" }}
                        >
                            <CgWebsite /> &nbsp;
                            בדוק כרטיס
                        </Button>
                    )}
                {showTb && (<Button
                    variant="primary"
                    onClick={() => props.twin(props.uid, props.curenthagrala)}
                    style={{ marginLeft: "10px" }}
                >
                    <CgWebsite /> &nbsp;
                    משוך זכיה
                </Button>)}

            </Card.Body>
        </Card>
    );
}
export default CardView;
