import React from 'react';
import $ from 'jquery';
import { SimpleAjax, amountToHebrewString } from "../include/Commons"
import { AuthContext } from '../utils/AuthContext';


export class NextChanceCounter extends React.Component {
    static contextType = AuthContext;
    constructor(props) {
        super(props);
        this.state = {
            data: [],
            isLoaded: false,
            isError: true,
            gameType: this.props.gameType,
            l_days: "00",
            l_hours: "00",
            l_minutes: "00",
            l_seconds: "00",
            l_lnumber: "00"
        }

        this.myApp = props.myApp;
        this.unmounted = false;
    }

    componentDidMount() {
        const { token, updatePoints, userID, logout } = this.context;
        let myurl = window.baseUrl + '/getnext';
        const fthis = this;
        SimpleAjax(myurl, (result, isError) => {
            console.log(result);
            if (result.success == 1) {
                fthis.setState({
                    data: result.next,
                    isLoaded: true,
                    isError: isError,
                });
            }

        }, this.myApp, 'POST', { userID }, token);

        if (this.state.isLoaded && !this.state.isError && this.state.gameType > 0 && this.state.data.length > 0) {
            this.componentDidUpdate();
        }
    }
    componentWillUnmount() {
        // Set a flag to indicate the component is unmounted
        //this.unmounted = true;
    }

    componentDidUpdate() {
        if (this.state.isLoaded && !this.state.isError && this.state.gameType > 0 && this.state.data.length > 0) {
            this.showUpdatedTimer();
        }
    }

    showUpdatedTimer() {
        const { token, userID, logout } = this.context;
        // Get todays date and time
        let gameData = this.state.data.filter(x => {
            if (x.game_type === this.state.gameType)
                return x;
            return null;
        });
        if (gameData.length === 1) gameData = gameData[0];
        else return;


        var now = new Date().getTime();
        var countDownDate = new Date(gameData.nextLottoryDate);

        const dayName = [
            "ביום ראשון",
            "ביום שני",
            "ביום שלישי",
            "ביום רביעי",
            "ביום חמישי",
            "ביום שישי",
            "במוצ׳׳ש"
        ];

        this.raffleDay = dayName[countDownDate.getDay()];

        // Find the distance between now an the count down date
        var distance = countDownDate - now;

        // Time calculations for days, hours, minutes and seconds
        var days = Math.floor(distance / (1000 * 60 * 60 * 24));
        var hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
        var seconds = Math.floor((distance % (1000 * 60)) / 1000);
        this.state.l_lnumber = gameData.lotteryNumber;
        // console.log('days=' + days + '; hours=' + hours + '; minutes=' + minutes);

        // If the count down is over, write some text 
        if (days === 0 && hours === 0 && minutes === 0 && seconds === 0) {
            let myurl = window.baseUrl + '/getnext';
            const fthis = this;
            SimpleAjax(myurl, (result, isError) => {
                console.log(result);
                if (result.success == 1) {
                    fthis.setState({
                        data: result.next,
                        isLoaded: true,
                        isError: isError
                    });
                } else {
                    logout();
                }
            }, this.myAppp, 'POST', { userID }, token);
        }

        if (Math.floor(days / 10) <= 0) {
            days = "0" + days;
        }
        if (Math.floor(hours / 10) <= 0) {
            hours = "0" + hours;
        }
        if (Math.floor(minutes / 10) <= 0) {
            minutes = "0" + minutes;
        }
        if (Math.floor(seconds / 10) <= 0) {
            seconds = "0" + seconds;

        }

        if (!isNaN(days) && !isNaN(minutes) && !isNaN(hours) && !isNaN(seconds)) {
            let l_days = days;
            let l_hours = hours;
            let l_minutes = minutes;
            this.state.l_seconds = seconds;
            // Output the result i
            $("#minuts" + this.state.gameType).html(minutes);
            $("#hours" + this.state.gameType).html(hours);
            $("#days" + this.state.gameType).html(days);
            $("#secs" + this.state.gameType).html(seconds);
        }
    }



    render() {
        let l_seconds = this.state.l_seconds;
        if (!this.state.isLoaded || this.state.isError || !(this.state.gameType > 0) || this.state.data.length < 1) return <div></div>;
        let gameData = this.state.data.filter(x => { if (x.game_type === this.state.gameType) return x; return null; });
        if (gameData.length === 1) gameData = gameData[0];
        else return <div></div>
        return (

            <div className="card-info-timer card-info-timer_green card-info-timer_small">
                <div className="card-info-timer__name">
                    <p > צ'אנס - הגרלה כל שעתיים  <br /> הגרלה {gameData.lotteryNumber} בעוד: </p>
                </div>
                <div className="card-info-timer__container-game">
                    <div className="card-info-timer__group">
                        <p className="card-info-timer__title"> שניות </p>
                        <div className="card-info-timer__cell-container">
                            <div className="card-info-timer__cell">
                                <p id={"secs" + this.state.gameType}>00</p>
                            </div>
                        </div>
                    </div>
                    <span className="card-info-timer__separate"></span>
                    <div className="card-info-timer__group">
                        <p className="card-info-timer__title"> דקות </p>
                        <div className="card-info-timer__cell-container">
                            <div className="card-info-timer__cell">
                                <p id={"minuts" + this.state.gameType}>00</p>
                            </div>
                        </div>
                    </div>
                    <span className="card-info-timer__separate"></span>
                    <div className="card-info-timer__group">
                        <p className="card-info-timer__title"> שעות </p>
                        <div className="card-info-timer__cell-container">
                            <div className="card-info-timer__cell">
                                <p id={"hours" + this.state.gameType}>00</p>
                            </div>
                        </div>
                    </div>
                    <span className="card-info-timer__separate"></span>
                    <div className="card-info-timer__group">
                        <p className="card-info-timer__title"> ימים </p>
                        <div className="card-info-timer__cell-container">
                            <div className="card-info-timer__cell">
                                <p id={"days" + this.state.gameType}>00</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div style={{ display: "none" }}>
                    {setInterval(() => {
                        this.showUpdatedTimer();
                    }, (1 * 1000))}
                </div>
            </div>
        )
    }
}



export class NextLoteryCounterIncludeLottoPrize extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.gameType = this.props.gameType;
        this.data = this.props.data;

    }


    render() {
        if (this.gameType !== 1) return <div></div>
        if (!this.data.firstPrize || this.data.firstPrize === 0) return <div></div>
        return (
            <div>
                <div className="tiker_line" />
                <div className="ticker_info_group">
                    <h3 className="ticker_price_title">:הפרס הראשון בהגרלה</h3>
                    <div className="archive_open_dates ticker">
                        <div className="cat_archive_txt open ticker">{amountToHebrewString(this.data.firstPrize)}</div>
                        <div className="cat_archive_txt open ticker">שקלים בלוטו</div>
                    </div><div className="archive_open_dates ticker">
                        <div className="cat_archive_txt open ticker">ועד</div>
                        <div className="cat_archive_txt open ticker">{amountToHebrewString(this.data.firstPrize * 2)}</div>
                        <div className="cat_archive_txt open ticker">שקלים בדאבל לוטו</div>
                    </div>
                </div>
                <div className="ticker_info_group">
                    <h3 className="ticker_price_title">:הפרס השני בהגרלה</h3>
                    <div className="archive_open_dates ticker">
                        <div className="cat_archive_txt open ticker">{amountToHebrewString(this.data.secondPrize)}</div>
                        <div className="cat_archive_txt open ticker">שקלים בלוטו</div>
                    </div>
                    <div className="archive_open_dates ticker">
                        <div className="cat_archive_txt open ticker">ועד</div>
                        <div className="cat_archive_txt open ticker">{amountToHebrewString(this.data.secondPrize * 2)}</div>
                        <div className="cat_archive_txt open ticker">שקלים בדאבל לוטו</div>
                    </div>
                </div>
                {
                    this.gameType === 1 ?
                        <>
                            <div>
                                <div className="tiker_line" />
                                <div className="ticker_info_group">
                                    <div className="cat_archive_txt open ticker" dir="rtl">ההגרלה משודרת בערוץ קשת 12, <a href="javascript:void(0)" onClick={() => { window.open('https://www.pais.co.il/info/live.aspx', '_system'); return false; }}>באתר</a> ובישומון מפעל הפיס בין השעות 23:00־23:40. <br />לבדיקת מועד השידור המדויק היכנסו לאתר <a href="javascript:void(0)" onClick={() => { window.open('https://www.mako.co.il/tv-tv-schedule', '_system'); return false; }}>MAKO</a>.</div>
                                </div>
                            </div>
                        </>
                        : null
                }
            </div>
        )
    }

}