import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import { BsGithub } from "react-icons/bs";

function CcardTotal(props) {
    const rows = [];
    let index = props.CardType == 5 ? 4 : props.CardType;
    let max = props.CardType == 5 ? 4 : props.CardType;

    for (let i = 0; i < max; i++) {
        const price = (props.MultiTable[props.CardType - 1][i] * props.CardPrice).toFixed(2);
        rows.push(
            <div className="small-content-table__row ng-star-inserted" key={`row_${i}`}>
                <div className="small-content-table__cell">
                    <p>{index}</p>
                </div>
                <div className="small-content-table__cell">
                    <p>{price} ₪</p>
                </div>
            </div>
        );
        index--;
    }
    rows.push(<div className="card-summary-additional__info_title"><p >משתתף בהגרלת צ'אנס: </p></div>);
    if (props.CardHagrlot == 1) {
        rows.push(<span className="bold"> {props.HagralaNumber} </span>);
    } else {
        const from = props.HagralaNumber;
        const to = props.HagralaNumber + props.CardHagrlot - 1;

        rows.push(<div className="card-summary-additional green-mod">
            <div className="card-summary-additional__info">
                <p >
                    <span className="ng-star-inserted">מ</span>
                    <span className="bold"> {from} </span></p>
            </div>
            <div className="card-summary-additional__info card-summary-additional__info_left ng-star-inserted">
                <p >
                    <span >עד</span>
                    <span className="bold"> {to} </span></p>
            </div>
        </div>)

    }

    let total = props.CardPrice * (props.card[0].length === 0 ? 1 : props.card[0].length);
    total *= props.card[1].length === 0 ? 1 : props.card[1].length;
    total *= props.card[2].length === 0 ? 1 : props.card[2].length;
    total *= props.card[3].length === 0 ? 1 : props.card[3].length;
    total *= props.CardHagrlot;
    let zerofim = total / props.CardPrice;

    rows.push(
        <div className="card-summary-total">
            <div className="card-summary-total__name">
                <p>
                    <span className="bold green"> סה''כ</span>
                </p>
            </div>
            <div className="card-summary-total__price">
                <p className="green"> {total} ₪ </p>
            </div>
        </div>
    );
    return (
        <Card className="project-card-view">
            <Card.Body>
                <Card.Title>{props.title + " " + props.CardPrice}</Card.Title>
                <div className="small-content">
                    <div className="small-content-table">
                        <div className="small-content-table__rowt small-content-table__row_title">
                            <div className="small-content-table__cell">
                                <p > קלפים נכונים </p>
                            </div>
                            <div className="small-content-table__cell">
                                <p > סכום זכייה </p>
                            </div>
                        </div>
                        {rows}
                    </div>

                </div>
            </Card.Body>
        </Card>
    );
}
export default CcardTotal;
