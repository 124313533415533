import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import $ from 'jquery';
import { AuthProvider } from "./utils/AuthContext";

window.$ = $;
//const root = ReactDOM.createRoot(document.getElementById('root'));
//let url="http://109.207.76.25/myc/";
console.log('Loaded environment variables:', process.env);

let url = process.env.REACT_APP_BASE_MAIN_URL; //"http://109.207.76.25/myc/";
window.baseUrl = url;

ReactDOM.render(
  <React.StrictMode>
    <AuthProvider>
    <App />
    </AuthProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
