import React, { createContext, useState ,useEffect} from 'react';
import useApi from '../utils/api'

const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [userPoints, setuserPoints] = useState(0);
  const [userID,setuserID] =  useState(sessionStorage.getItem('id') || null);
  const [token, setToken] = useState(sessionStorage.getItem('accessToken') || null);
  const { post } = useApi(token);

  const updatePoints = (points)=>
  {
    setuserPoints(points);
  }
  const login = (newToken,points,uid) => {
    // Logic to authenticate user
    setIsLoggedIn(true);
    setToken(newToken);
    setuserPoints(points);
    setuserID(uid);
    sessionStorage.setItem('accessToken', newToken);
    sessionStorage.setItem('id', uid);
  };

  const logout = () => {
    // Logic to log out user
    setIsLoggedIn(false);
    setToken(null); 
    setuserPoints(null);
    sessionStorage.removeItem('accessToken');
    sessionStorage.removeItem('id');
  };
  useEffect(() => {
    let isMounted = true;
    if (token && userID) {
        const fetchUserPoints = async () => {
            try {
                const response = await post(('/userp'),
                JSON.stringify({ userID })
            
            );
            console.log(JSON.stringify(response?.data));
             if(response.data.success == 1){
              if (isMounted) { // Check if the component is still mounted before updating state
                setuserPoints(response.data.points);
              }
             }else{
              logout();
             }
            
            } catch (error) {
              // Handle error
              console.error('Error fetching user points:', error);
            }
          };
    
      fetchUserPoints();
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    return () => {
      isMounted = false; // Set isMounted to false when component unmounts
    };
  }, [token,userID]);
  return (
    
    <AuthContext.Provider value={{ token,userPoints,isLoggedIn,userID, login, logout ,updatePoints}}>
      {children}
    </AuthContext.Provider>
  );
};

export { AuthProvider, AuthContext };