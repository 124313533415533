import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import CardView from "./CardView";
import useApi from "../../utils/api"
import { useRef, useState, useEffect, useContext } from "react";
import { AuthContext } from '../../utils/AuthContext';
import { HiArrowCircleRight, HiArrowCircleLeft } from "react-icons/hi";
import { useNavigate } from 'react-router-dom';

function History() {
    const navigate = useNavigate();
    const { token, updatePoints, logout,userID } = useContext(AuthContext);
    const { post } = useApi(token);
    const [cardData, setcardData] = useState(null);

    const [selectview, setselectview] = useState(null);
    const [selectviewindex, setselectviewindex] = useState(0);
    useEffect(() => {

        const fetchUserCards = async () => {
            try {
                const response = await post(('/view'),
                    JSON.stringify({ userID })
                );
                //console.log(JSON.stringify(response?.data));
                if (response.data.success == 0) {
                    logout();
                } else {
                    setcardData(response.data);
                    setselectview(response.data.las_view);
                }

            } catch (error) {
                // Handle error
                console.error('Error fetching view History:', error);
            }
        };

        fetchUserCards();


    }, []);

    
    const HidC = async (uid)=>{
        console.log("hide card: " + uid );
        const response = await post(('/sub'),
            JSON.stringify({ uid ,index:2,userID})
        );
        if(response.data.success == 1){
            const cardIndex = cardData.cards.findIndex(card => card.uid.toString() === uid.toString());
            if (cardIndex !== -1) {
                const updatedCards = cardData.cards.filter((_, index) => index !== cardIndex);
                setcardData(prevCardData => ({
                    ...prevCardData,
                    cards: updatedCards
                }));
            }
        }
    }
 const resendCard = (card)=>{
    const cardd = cardData?.cards.find(c => c.uid === card.uid);
    if(card.title ==="צ'אנס רגיל"){
        navigate('/f-ragil',  { state: JSON.stringify(cardd) } );
    }else if(card.title ==="רב צ'אנס"){
        navigate('/f-rav',  { state: JSON.stringify(cardd) } );
    }else if(card.title ==="צ'אנס שיטתי"){
        navigate('/f-shita',  { state: JSON.stringify(cardd) } );
    }
    
 }
    return (
        <Container fluid className="project-section">
            <Particle />
            <Container>
                <h1 className="project-heading">
                    <strong className="purple">היסטורית שליחות</strong>
                </h1>

                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                    {cardData?.cards
                        .filter(card => card.card_chek == 1 && parseInt(card.hide_view1) === 1 && parseInt(card.hide_view2) === 0)
                        .map((card, index) => (
                            <Col md={4} className="project-card" key={index}>
                                <CardView
                                    title={card.card_type}
                                    price={card.card_price}
                                    hagrlot={card.card_hagrlot}
                                    total={card.card_total}
                                    datet={card.datetime}
                                    carddata={card.card_data}
                                    isChek={card.card_chek == 1 ? true : false}
                                    hagralanum={card.hagrala_num}
                                    isshita={card.card_type == "צ'אנס שיטתי" ? true : false}
                                    cardWin={card.Card_Win}
                                    uid={card.uid}
                                    hidec={HidC}
                                    resendCard={resendCard}
                                    curenthagrala={parseInt(selectview?.[selectviewindex]?.Chance_Num)}
                                />
                            </Col>
                        ))}
                </Row>
            </Container>
        </Container>
    );
}

export default History;
