import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import $ from 'jquery';
function Ccardprice(props) {
    //const data = "This is data from Child Component to the Parent Component."
    const priceTable = {
        1:[[5, 10, 25, 50], [70, 100, 250, 500]],
        2:[[5, 10, 25, 50], [70, 100, 250, 500]],
        3:[[5, 10, 25, 50], [70, 100]],
        4:[[5, 10, 25], []]
    };
    
    let price_selection = -1;
    let c_price = 0;
    var PriceClike = function changeColor1(price, id) {
        $('p[id^="p"]').removeClass("active");
        if (price_selection != -1) {
            $("#p" + price_selection).removeClass("active");
        }
        price_selection = id;
        c_price = price;
        console.log('Price Clike:  ' + id + " -> " + price);

        $("#p" + id).addClass("active");
        props.onChange(price)
    };

    return (
        <Card className="project-card-view">
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <div className="cards-list">
                    {
                        priceTable[props.CardType].map((prices, indexs) => {
                            return (
                                prices.map((price, index) => {
                                    return (<p id={"p" + indexs + "" + index} onClick={() => PriceClike(price, indexs + "" + index)}  key={index}className={`cards-list__card ${props.CardPrice ==  price ? 'active' : ''}`} >{price}</p>);
                                })
                            );
                        })
                    }
                </div>
            </Card.Body>
        </Card>
    );
}
export default Ccardprice;
