import { React } from "react";
import Card from "react-bootstrap/Card";

import $ from 'jquery';

function Ccardhagrlot(props) {
    let type_selection = -1;
    let c_type = 0;
    var TypeClike = function changeColor2(type, id) {

        $('p[id^="h"]').removeClass("active");
        if (type_selection != -1) {
            $("#h" + type_selection).removeClass("active");
        }
        type_selection = id;
        c_type = type;
        console.log('Hagrlot Clike:  ' + id + " -> " + type);

        $("#h" + id).addClass("active");
        props.onChange(type)
    };

    return (
        <Card className="project-card-view">
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <div className="cards-list">
                    {
                        props.typearr.map((types, indexs) => {
                            return (
                                types.map((type, index) => {
                                    return (<p id={"h" + indexs + "" + index} key={index} onClick={() => TypeClike(type, indexs + "" + index)} className={`cards-list__card ${props.CardHagrlot == type ? 'active' : ''}`}  >{type}</p>);
                                })
                            );
                        })
                    }
                </div>
            </Card.Body>
        </Card>
    );
}
export default Ccardhagrlot;
