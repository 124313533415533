import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import CardView from "./CardView";
import useApi from "../../utils/api"
import { useRef, useState, useEffect, useContext } from "react";
import { AuthContext } from '../../utils/AuthContext';
import { HiArrowCircleRight, HiArrowCircleLeft } from "react-icons/hi";
function MyCard() {
    const { token, updatePoints,userID,logout } = useContext(AuthContext);
    const { post } = useApi(token);
    const [cardData, setcardData] = useState(null);

    const [selectview, setselectview] = useState(null);
    const [serverTime, setserverTime] = useState(null);
    const [selectviewindex, setselectviewindex] = useState(0);
    useEffect(() => {

        const fetchUserCards = async () => {
            try {
                const response = await post(('/view'),
                JSON.stringify({ userID })
                );
                //console.log(JSON.stringify(response?.data));
                if(response.data.success == 0){
                    logout();
                }else{
                    setcardData(response.data);
                    setselectview(response.data.las_view);
                    setserverTime(response.datenow);
                }
                
            } catch (error) {
                // Handle error
                console.error('Error fetching user points:', error);
            }
        };

        fetchUserCards();


    }, []);
    const LastView = async()=>{
        try {
            const response = await post(('/view'),
            JSON.stringify({ userID })
            );
            //console.log(JSON.stringify(response?.data));
            if(response.data.success == 0){
                logout();
            }else{
                setcardData(response.data);
                setselectview(response.data.las_view);
                setserverTime(response.datenow);
            }
            
        } catch (error) {
            // Handle error
            console.error('Error fetching user points:', error);
        }
    }
    const CardSelect = async (number, amount) => {

        if (amount > 1) {
            let to = parseInt(number) + parseInt(amount) - 1;
            console.log("mumber " + number + " To " + to + " amount " + amount);
            const response = await post(('/getdata'),
                JSON.stringify({ number, to, amount })
            );

            if(response.data.length > 0){
                setselectviewindex(0);
                setselectview(response.data);
            }

        } else {
            console.log("mumber " + number + "amount " + amount);
            const response = await post(('/getdata'),
                JSON.stringify({ number, amount })
            );
            if(response.data.length > 0){
                setselectviewindex(0);
                setselectview(response.data);
            }else{
                LastView();
            }
            console.log(response);
        }
    }
    const NextView = () => {
        if (selectviewindex <= selectview?.length) {
            setselectviewindex(selectviewindex + 1);
        }
    }
    const PreView = () => {
        if (selectviewindex > 0) {
            setselectviewindex(selectviewindex - 1);
        }
    }
    const TakeWin = async (uid,hnum)=>
    {
        console.log("Win card: " + uid + " hagrala: " + hnum);
        uid = parseInt(uid);
        hnum = parseInt(hnum);
        const response = await post(('/chku'),
            JSON.stringify({ uid, hnum,userID })
        );

        const newData = response.data; 
        const cardIndex = cardData.cards.findIndex(card => card.uid.toString() === uid.toString());

        if (cardIndex !== -1) {

            const updatedCards = [...cardData.cards]; 
            updatedCards[cardIndex].Card_Win = JSON.stringify(newData.cards);
            //updatedCards[cardIndex].card_chek = newData.is_cheked;

            // Update the cardData state
            setcardData(prevCardData => ({
                ...prevCardData,
                cards: updatedCards
            }));
            updatePoints(newData.points);
            setselectviewindex(selectviewindex);
        }

    }
    const HidC = async (uid)=>{
        console.log("hide card: " + uid );
        const response = await post(('/sub'),
            JSON.stringify({ uid ,index:1,userID})
        );
        if(response.data.success == 1){
            const cardIndex = cardData.cards.findIndex(card => card.uid.toString() === uid.toString());
            if (cardIndex !== -1) {
                const updatedCards = cardData.cards.filter((_, index) => index !== cardIndex);
                setcardData(prevCardData => ({
                    ...prevCardData,
                    cards: updatedCards
                }));
            }
        }
    }
    const OnCheckCardClick = async (uid, hnum) => {
        console.log("chk card: " + uid + " hagrala: " + hnum);
        uid = parseInt(uid);
        hnum = parseInt(hnum);
        const response = await post(('/chk'),
            JSON.stringify({ uid, hnum ,userID})
        );
        const newData = response.data; // Assuming response is JSON

        // Find the index of the card with the corresponding uid in the cards array
        const cardIndex = cardData.cards.findIndex(card => card.uid.toString() === uid.toString());

        if (cardIndex !== -1) {
            // Update the Card_Win property of the found card
            const updatedCards = [...cardData.cards]; // Clone the cards array
            updatedCards[cardIndex].Card_Win = JSON.stringify(newData.cards);
            updatedCards[cardIndex].card_chek = newData.is_cheked;

            // Update the cardData state
            setcardData(prevCardData => ({
                ...prevCardData,
                cards: updatedCards
            }));
            setselectviewindex(selectviewindex);
        }
    }
    
    return (
        <Container fluid className="project-section">
            <Particle />
            <Container>
                <h1 className="project-heading">
                    <strong className="purple">הטפסים שלי </strong>
                </h1>
                <div className="comlex_line_list" style={{ position: "sticky", top: 120, zIndex: 1, backgroundColor: "darkgreen" }}>
                    <div className="complex_stiky_title limit">
                        <div className="complex_line_block limit" style={{ display: "flex", justifyContent: "space-around", color: "white" }}>
                            <div className="w-hidden-tiny">{selectviewindex + 1}/{selectview?.length}</div>
                            <div className="stiky_title_num w-hidden-main w-hidden-medium w-hidden-small">#{selectview?.[selectviewindex]?.Chance_Num}</div>
                            <div className="w-hidden-tiny">{selectview?.[selectviewindex]?.Time}</div>
                            <div className="w-hidden-tiny">{selectview?.[selectviewindex]?.Date}</div>
                        </div>
                        <div className="cards-k" style={{ justifyContent: "center", paddingBottom: "5px" }}>
                            
                            <div onClick={selectviewindex === 0 ? null : () => PreView()} className="next-pre" style={{ display: "flex", alignItems: "center" }}>
                                <HiArrowCircleLeft size={40} color="gray" style={{ opacity: selectviewindex === 0 ? 0 : 1 }} />
                            </div>
                            <div className="item-c active">
                                <div className="card-T card0-f primary-B card-R"></div>
                                <div className="value-L"> {selectview?.[selectviewindex]?.Result[0]}</div>
                            </div>
                            <div className="item-c active">
                                <div className="card-T card1-O primary-B card-R"></div>
                                <div className="value-L"> {selectview?.[selectviewindex]?.Result[1]}</div>
                            </div>
                            <div className="item-c active">
                                <div className="card-T card2-u primary-B card-R"></div>
                                <div className="value-L"> {selectview?.[selectviewindex]?.Result[2]}</div></div>
                            <div className="item-c active">
                                <div className="card-T card3-C primary-B card-R"></div>
                                <div className="value-L"> {selectview?.[selectviewindex]?.Result[3]}</div>
                            </div>
                            <div onClick={selectviewindex === selectview?.length - 1 ? null : () => NextView()} className="next-pre" style={{ display: "flex", alignItems: "center" }}>
                                <HiArrowCircleRight size={40} color="gray" style={{ opacity: selectviewindex === selectview?.length - 1 ? 0 : 1 }} />
                            </div>
                           
                        </div>
                    </div>
                </div>
                <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
                {cardData?.cards?.filter(card => parseInt(card.hide_view1) === 0)?.map((card, index) => (
                        <Col md={4} className="project-card" key={index}>
                            <CardView
                                title={card.card_type}
                                card_game_type = {card.card_game_type}
                                price={card.card_price}
                                hagrlot={card.card_hagrlot}
                                total={card.card_total}
                                datet={card.datetime}
                                carddata={card.card_data}
                                isChek={card.card_chek == "1" ? true : false}
                                hagralanum={card.hagrala_num}
                                isshita={card.card_type == "צ'אנס שיטתי" ? true : false}
                                onSelect={CardSelect}
                                cardWin={card.Card_Win}
                                prize={card.Win_Prize}
                                uid={card.uid}
                                curenthagrala={parseInt(selectview?.[selectviewindex]?.Chance_Num)}
                                chkclike={OnCheckCardClick}
                                hidec={HidC}
                                twin={TakeWin}
                            />
                        </Col>
                    ))}
                </Row>
            </Container>
        </Container>
    );
}

export default MyCard;
