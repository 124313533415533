import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import { BsGithub } from "react-icons/bs";
import "./Cshita.css"
function Ccardselect(props) {

    const GetItemCname = (name, row, index) => {
        let retv = name;
        if (props.card[row][index]) {
            retv += " active";
        }
        if (props.selected === row) {
            retv += " selected";
        }
        return retv;
    }
    return (
        <Card className="project-card-view">
            <Card.Body>
                <Card.Title>{props.title + " " + `${props.CardType == 5 ? 'לרב צאנס' : props.CardType}`}</Card.Title>
                <div className="general-n" >
                    <div className="general-i">

                    </div>
                    <div className="general-O">
                        <div className="content-E">
                            <div className="header-C" >
                                <div className="actions-j">
                                    <button onClick={()=> props.ClearCard()} type="button" data-testid="clear-table" className="general-g icon-U">
                                        <span className="icon-k trash-b"></span>
                                    </button>
                                    <button  onClick={()=> props.AutoG()} type="button" data-testid="fill-table" className="general-g icon-U">
                                        <span className="icon-k magic-m"></span>
                                    </button>
                                </div>
                            </div>
                            <div onClick={() => props.CardSelect(0)} className={`row-P ${props.selected == 0 ? 'selected-F row-0' : ' row-0'}`} >
                                <div className="cards-k">
                                    <div className={GetItemCname("item-c", 0, 0)}>
                                        <div className="card-T card0-f primary-B card-R"></div>
                                        <div className="value-L"> {props.card[0][0]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 0, 1)}>
                                        <div className="card-T card0-f primary-B card-R"></div>
                                        <div className="value-L"> {props.card[0][1]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 0, 2)}>
                                        <div className="card-T card0-f primary-B card-R"></div>
                                        <div className="value-L"> {props.card[0][2]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 0, 3)}>
                                        <div className="card-T card0-f primary-B card-R"></div>
                                        <div className="value-L"> {props.card[0][3]}</div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => props.CardSelect(1)} className={`row-P ${props.selected == 1 ? 'selected-F row-1' : ' row-1'}`} >
                                <div className="cards-k">
                                    <div className={GetItemCname("item-c", 1, 0)}>
                                        <div className="card-T card1-O primary-B card-R"></div>
                                        <div className="value-L"> {props.card[1][0]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 1, 1)}>
                                        <div className="card-T card1-O primary-B card-R"></div>
                                        <div className="value-L"> {props.card[1][1]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 1, 2)}>
                                        <div className="card-T card1-O primary-B card-R"></div>
                                        <div className="value-L"> {props.card[1][2]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 1, 3)}>
                                        <div className="card-T card1-O primary-B card-R"></div>
                                        <div className="value-L"> {props.card[1][3]}</div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => props.CardSelect(2)} className={`row-P ${props.selected == 2 ? 'selected-F row-2' : ' row-2'}`}>
                                <div className="cards-k">
                                    <div className={GetItemCname("item-c", 2, 0)}>
                                        <div className="card-T card2-u primary-B card-R"></div>
                                        <div className="value-L"> {props.card[2][0]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 2, 1)}>
                                        <div className="card-T card2-u primary-B card-R"></div>
                                        <div className="value-L"> {props.card[2][1]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 2, 2)}>
                                        <div className="card-T card2-u primary-B card-R"></div>
                                        <div className="value-L"> {props.card[2][1]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 2, 3)}>
                                        <div className="card-T card2-u primary-B card-R"></div>
                                        <div className="value-L"> {props.card[2][3]}</div>
                                    </div>
                                </div>
                            </div>
                            <div onClick={() => props.CardSelect(3)} className={`row-P ${props.selected == 3 ? 'selected-F row-3' : ' row-3'}`}>
                                <div className="cards-k">
                                    <div className={GetItemCname("item-c", 3, 0)}>
                                        <div className="card-T card3-C primary-B card-R"></div>
                                        <div className="value-L"> {props.card[3][0]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 3, 1)}>
                                        <div className="card-T card3-C primary-B card-R"></div>
                                        <div className="value-L"> {props.card[3][1]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 3, 2)}>
                                        <div className="card-T card3-C primary-B card-R"></div>
                                        <div className="value-L"> {props.card[3][2]}</div>
                                    </div>
                                    <div className={GetItemCname("item-c", 3, 3)}>
                                        <div className="card-T card3-C primary-B card-R"></div>
                                        <div className="value-L"> {props.card[3][3]}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </Card.Body>
        </Card>
    );
}
export default Ccardselect;
