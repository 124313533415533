import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { FaTrash } from "react-icons/fa";
import { BsGithub } from "react-icons/bs";

function Ccardselect(props) {

    const GetItemCname = (row)=>{
        let retv = "item";
        if (props.card[row][0]) {
            retv += " active";
        }
        if (props.selected === row) {
            retv += " selected";
        }
        return retv;    
    }
    return (
        <Card className="project-card-view">
            <Card.Body>
                <Card.Title>{props.title + " " + props.CardType}</Card.Title>
                <div className="mrow selected row-0" data-testid="row-0">
                    <button onClick={()=> props.ClearCard()} type="button" data-testid="clear-row-0" className="generalt" style={{ padding: "0px 8px" }}>
                        <span className="icon trash">
                        </span>
                    </button>
                    <div className="cards">

                        <div onClick={()=> props.CardSelect(0)} className={GetItemCname(0)} >
                            <div className="cardp card0 primary card">
                            </div>
                            <div className="cvalue">
                                {props.card[0][0]}
                            </div>
                        </div>
                        <div onClick={()=> props.CardSelect(1)} className={GetItemCname(1)} >
                            <div className="cardp card1 primary card">
                            </div>
                            <div className="cvalue">
                                {props.card[1][0]}
                            </div>
                        </div>
                        <div onClick={()=> props.CardSelect(2)} className={GetItemCname(2)} >
                            <div className="cardp card2 primary card">
                            </div>
                            <div className="cvalue">
                                {props.card[2][0]}
                            </div>
                        </div>
                        <div onClick={()=> props.CardSelect(3)} className={GetItemCname(3)} >
                            <div className="cardp card3 primary card">
                            </div>
                            <div className="cvalue">
                                {props.card[3][0]}
                            </div>
                        </div>
                    </div>
                    <button onClick={()=> props.AutoG()} type="button" data-testid="fill-row-0" className="generalt" style={{ padding: "0px 8px" }}>
                        <span className="icon magic">
                        </span>
                    </button>
                </div>
            </Card.Body>
        </Card>
    );
}
export default Ccardselect;
