import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import Particle from "../Particle";
import Ccardprice from "./Ccardprice";
import '../Cragil/Cragil.css';
import Ccardselect from "../Cragil/Ccardselect";
import { useState, useEffect, useContext } from 'react';
import $ from 'jquery';
import Ccardhagrlot from "../Cragil/Ccardhagrlot";

import { fetchData } from '../../utils/tools';
import CcardTotal from "../Cragil/CcardTotal";
import useApi from '../../utils/api'
import { AuthContext } from '../../utils/AuthContext';
import { Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
function Crav() {
  const routeLocation = useLocation();
  const { token, updatePoints, userID, logout } = useContext(AuthContext);
  const { post } = useApi(token);
  const navigate = useNavigate();
  const winMultiply = [[5],
  [30, 0.5],
  [300, 0.7, 0.3],
  [1000, 20, 2, 0.5],
  [1000, 20, 2, 0.5]]
  const CardIndex = ['7', '8', '9', '10', 'J', 'Q', 'K', 'A']
  const [CardPrice, setCardPrice] = useState(5);
  const [CardType, setCardType] = useState(5);
  const [CardHagrlot, setCardHagrlot] = useState(1);
  const [CardData, setCardData] = useState([
    [],
    [],
    [],
    []]);
  const [selected, setselected] = useState(null);
  const [HagralaNum, setHagralaNum] = useState(null);

  const [okToSend, setokToSend] = useState(0);

  const PriceChane = (price) => {
    setCardPrice(price);
  }
  const TypeChane = (type) => {
    console.log(type);

    setCardType(type)

  }
  const HagrlotChane = (hcount) => {
    console.log(hcount);

    setCardHagrlot(hcount)

  }
  useEffect(() => {
    // This will be called whenever userPoints changes
    // You can add any additional logic here if needed
    console.log("Token changed:", token);
  }, [token]);


  var PresendCard = async function changeColor() {

    console.log('PreSendClick');
    const rowsLengths = findNonEmptyRowIndices();
    const game_name = "רב צ'אנס";
    const total = CardPrice * CardHagrlot;
    if (rowsLengths.length == CardType - 1) {
      console.log('Send Request To Server');
      const response = await post(('/card'),
        JSON.stringify({ game_name, CardPrice, CardType: 4, CardHagrlot, total, HagralaNum, CardData,userID })
      );
      const status = response?.data?.success;


      setokToSend(1);
      if (status === 1) {
        const points = response?.data?.points;
        updatePoints(points);

        navigate('/');
        //window.location.reload();
      }
    } else {
      //not fill all table
      console.log('Not Fill All Table');
    }
    console.log('Click Send Button');
    return () => {
      // Cancel or cleanup any ongoing asynchronous tasks or subscriptions
    };
  };



  const SetCardValue = (row, val) => {
    const rowsLengths = findNonEmptyRowIndices();
    let IsOk = false;
    if (rowsLengths.length < CardType - 1) {
      IsOk = true;
    }
    else {
      IsOk = rowsLengths.includes(row);

    }

    const isOk2 = rowsLengths.length == CardType - 1 && rowsLengths.includes(row);
    if (!IsOk) {
      KeboardClose();
      return;
    }

    let c = CardIndex[val];
    let updatedCardData = [...CardData];
    updatedCardData[row] = [c];
    setCardData(updatedCardData);
    let TotalSelect = updatedCardData[0].length + updatedCardData[1].length + updatedCardData[2].length + updatedCardData[3].length;
    if (TotalSelect == CardType - 1) {
      KeboardClose();
    }
  }
  const AutoGenerate = () => {
    const ctype = [1, 2, 3, 4];
    const Card = ['7', '8', '9', '10', 'J', 'Q', 'K', 'A'];
    const count = 2;
    const output = [[], [], [], []];

    for (let i = 0; i < CardType - 1; i++) {
      const index = Math.floor(Math.random() * ctype.length);
      const cnum = Math.floor(Math.random() * Card.length);
      output[ctype[index] - 1].push(Card[cnum]); // Adjust index to start from 0
      ctype.splice(index, 1); // Remove selected index from ctype
    }

    setCardData(output);
  }
  const findNonEmptyRowIndices = () => {
    const nonEmptyRowIndices = CardData.reduce((indices, row, index) => {
      if (row.length > 0) {
        indices.push(index);
      }
      return indices;
    }, []);
    return nonEmptyRowIndices;
  }
  const CardClike = (row) => {
    setselected(row);
    $("#keyboard").addClass("open");
    console.log(row);
  }
  const ClearCard = () => {

    setCardData([
      [],
      [],
      [],
      []]);
  }
  function KeboardClose() {
    $("#keyboard").removeClass("open");
  }

  useEffect(() => {
    const fetchDataFromAPI = async () => {
      try {
        //setIsLoaded(false);
        //setIsLoaded(false);
        const response = await post(('/getnext'),
          JSON.stringify({ userID })
        );
        //const result = await fetchData(window.baseUrl + '/getnext',"POST",{ userID });
        //const response = JSON.parse(result);
        if (response.data.success != 1) {
          logout();
        } else {
          setHagralaNum(response.data.next[2].lotteryNumber);
        }
        //setIsLoaded(true);
      } catch (error) {
        console.error('Error getnext Rav:', error);
        logout();
        //setError(error.message);
        //setIsLoaded(true);
      }
    };

    fetchDataFromAPI();
    return () => {
    };
  }, []);
  const MaxType = {
    1: 500,
    2: 500,
    3: 100,
    4: 25,
    5: 50
  }

  if (CardPrice > MaxType[CardType]) {
    setCardPrice(MaxType[CardType]);
  }
  const rowsLengths = findNonEmptyRowIndices();
  let IsOk = rowsLengths.length === CardType - 1;
  useEffect(() => {
    if (routeLocation.state != null) {
      let Clone_Card = JSON.parse(routeLocation.state);
      let CardD = JSON.parse(Clone_Card.card_data);
      setCardPrice(parseInt(Clone_Card.card_price));
      //setCardType(parseInt(Clone_Card.card_game_type));
      setCardHagrlot(parseInt(Clone_Card.card_hagrlot));
      setCardData(CardD);
    }
  }, [routeLocation.state]); 
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          <strong className="orange">רב צ'אנס </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={1} className="project-card">
            <Ccardprice
              pricearr={[[5, 10, 25, 50], [70, 100, 250, 500]]}
              title="בחר מחיר"
              onChange={PriceChane}
              CardPrice={CardPrice}
              CardType={CardType}
            />
          </Col>
          <Col md={1} className="project-card">
            <Ccardselect
              title="בחר קלפים"
              card={CardData}
              CardSelect={CardClike}
              ClearCard={ClearCard}
              AutoG={AutoGenerate}
              selected={selected}
              CardType={CardType - 1}
            />
          </Col>
          <Col md={1} className="project-card">
            <Ccardhagrlot
              typearr={[[1, 2, 4, 8]]}
              title="מספר הגרלות"
              onChange={HagrlotChane}
              CardHagrlot={CardHagrlot}
            />
          </Col>
          <Col md={1} className="project-card">
            <CcardTotal
              title="עלות"
              CardPrice={CardPrice}
              CardType={CardType - 1}
              CardHagrlot={CardHagrlot}
              HagralaNumber={HagralaNum}
              MultiTable={winMultiply}
            />
          </Col>
          <div id="keyboard" className="kekeyboard">
            <div className="keheader">
              <div className="keleft">
                <div className="keactions">
                  <button onClick={ClearCard} type="button" data-testid="clear-line" className="kegenerala dark-outline">
                    <span className="keicon trash">
                    </span>
                  </button>
                  <button onClick={AutoGenerate} type="button" data-testid="fill-line" className="kegenerala dark-outline">
                    <span className="keicon magic">
                    </span>
                  </button>
                </div>
              </div>
              <button onClick={KeboardClose} type="button" data-testid="keyboard-close" className="kegenerala dark-outline">
                <span className="keicon close"></span>
              </button>
            </div>
            <div className="kecontenta">
              <div className="kegeneralb">
                <div className="kecontentb">
                  <div className={`kerowa ${selected == 0 ? 'highlighted' : ''}`} >
                    <div onClick={() => SetCardValue(0, 0)} className={`keitema value ${CardData[0][0] === '7' ? 'selected' : ''}`} >7</div>
                    <div onClick={() => SetCardValue(0, 1)} className={`keitema value ${CardData[0][0] === '8' ? 'selected' : ''}`} >8</div>
                    <div onClick={() => SetCardValue(0, 2)} className={`keitema value ${CardData[0][0] === '9' ? 'selected' : ''}`} >9</div>
                    <div onClick={() => SetCardValue(0, 3)} className={`keitema value ${CardData[0][0] === '10' ? 'selected' : ''}`} >10</div>
                    <div onClick={() => SetCardValue(0, 4)} className={`keitema value ${CardData[0][0] === 'J' ? 'selected' : ''}`} >J</div>
                    <div onClick={() => SetCardValue(0, 5)} className={`keitema value ${CardData[0][0] === 'Q' ? 'selected' : ''}`} >Q</div>
                    <div onClick={() => SetCardValue(0, 6)} className={`keitema value ${CardData[0][0] === 'K' ? 'selected' : ''}`} >K</div>
                    <div onClick={() => SetCardValue(0, 7)} className={`keitema value ${CardData[0][0] === 'A' ? 'selected' : ''}`} >A</div>
                    <div className="kecard card0 outline itema"></div>
                  </div>
                  <div className={`kerowa ${selected == 1 ? 'highlighted' : ''}`} >
                    <div onClick={() => SetCardValue(1, 0)} className={`keitema value ${CardData[1][0] === '7' ? 'selected' : ''}`} >7</div>
                    <div onClick={() => SetCardValue(1, 1)} className={`keitema value ${CardData[1][0] === '8' ? 'selected' : ''}`} >8</div>
                    <div onClick={() => SetCardValue(1, 2)} className={`keitema value ${CardData[1][0] === '9' ? 'selected' : ''}`} >9</div>
                    <div onClick={() => SetCardValue(1, 3)} className={`keitema value ${CardData[1][0] === '10' ? 'selected' : ''}`} >10</div>
                    <div onClick={() => SetCardValue(1, 4)} className={`keitema value ${CardData[1][0] === 'J' ? 'selected' : ''}`} >J</div>
                    <div onClick={() => SetCardValue(1, 5)} className={`keitema value ${CardData[1][0] === 'Q' ? 'selected' : ''}`} >Q</div>
                    <div onClick={() => SetCardValue(1, 6)} className={`keitema value ${CardData[1][0] === 'K' ? 'selected' : ''}`} >K</div>
                    <div onClick={() => SetCardValue(1, 7)} className={`keitema value ${CardData[1][0] === 'A' ? 'selected' : ''}`} >A</div>
                    <div className="kecard card1 outline itema"></div>
                  </div>
                  <div className={`kerowa ${selected == 2 ? 'highlighted' : ''}`} >
                    <div onClick={() => SetCardValue(2, 0)} className={`keitema value ${CardData[2][0] === '7' ? 'selected' : ''}`} >7</div>
                    <div onClick={() => SetCardValue(2, 1)} className={`keitema value ${CardData[2][0] === '8' ? 'selected' : ''}`} >8</div>
                    <div onClick={() => SetCardValue(2, 2)} className={`keitema value ${CardData[2][0] === '9' ? 'selected' : ''}`} >9</div>
                    <div onClick={() => SetCardValue(2, 3)} className={`keitema value ${CardData[2][0] === '10' ? 'selected' : ''}`} >10</div>
                    <div onClick={() => SetCardValue(2, 4)} className={`keitema value ${CardData[2][0] === 'J' ? 'selected' : ''}`} >J</div>
                    <div onClick={() => SetCardValue(2, 5)} className={`keitema value ${CardData[2][0] === 'Q' ? 'selected' : ''}`} >Q</div>
                    <div onClick={() => SetCardValue(2, 6)} className={`keitema value ${CardData[2][0] === 'K' ? 'selected' : ''}`} >K</div>
                    <div onClick={() => SetCardValue(2, 7)} className={`keitema value ${CardData[2][0] === 'A' ? 'selected' : ''}`} >A</div>
                    <div className="kecard card2 outline itema"></div>
                  </div>
                  <div className={`kerowa ${selected == 3 ? 'highlighted' : ''}`} >
                    <div onClick={() => SetCardValue(3, 0)} className={`keitema value ${CardData[3][0] === '7' ? 'selected' : ''}`} >7</div>
                    <div onClick={() => SetCardValue(3, 1)} className={`keitema value ${CardData[3][0] === '8' ? 'selected' : ''}`} >8</div>
                    <div onClick={() => SetCardValue(3, 2)} className={`keitema value ${CardData[3][0] === '9' ? 'selected' : ''}`} >9</div>
                    <div onClick={() => SetCardValue(3, 3)} className={`keitema value ${CardData[3][0] === '10' ? 'selected' : ''}`} >10</div>
                    <div onClick={() => SetCardValue(3, 4)} className={`keitema value ${CardData[3][0] === 'J' ? 'selected' : ''}`} >J</div>
                    <div onClick={() => SetCardValue(3, 5)} className={`keitema value ${CardData[3][0] === 'Q' ? 'selected' : ''}`} >Q</div>
                    <div onClick={() => SetCardValue(3, 6)} className={`keitema value ${CardData[3][0] === 'K' ? 'selected' : ''}`} >K</div>
                    <div onClick={() => SetCardValue(3, 7)} className={`keitema value ${CardData[3][0] === 'A' ? 'selected' : ''}`} >A</div>
                    <div className="kecard card3 outline itema"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div onClick={PresendCard} className={`generalsend ${IsOk ? 'ok' : ''}`} data-testid="submit-purchase">שלח טופס

          </div>
        </Row>
      </Container>
    </Container>
  );
}

export default Crav;
