import { Outlet,Navigate } from "react-router-dom";
import { AuthContext } from '../utils/AuthContext';
import React, { useContext,useState,useEffect } from 'react';

const PrivateRoutes = ({ allowedRoles }) => {

    const { isLoggedIn } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Set loading to false once the authentication state is retrieved
        setLoading(false);
    }, [isLoggedIn]);
   
    
    if (loading) {
        // Render loading state or spinner while authentication state is being retrieved
        return <div>Loading...</div>;
    }
    return (
        isLoggedIn
            ? <Outlet />
            : <Navigate to="/login"  replace />
    );
}

export default PrivateRoutes;