import axios from 'axios';


const BASE_URL = process.env.REACT_APP_BASE_MAIN_URL; //'http://109-207-76-25.cloud-xip.com//myc/Rest'; // Replace with your API base URL

const useApi = (token) => {


  // Create an Axios instance with default configurations
  const api = axios.create({
    baseURL: BASE_URL,
    headers: {
      'Content-Type': 'application/json',
      Authorizationt: token ? `${token}` : '', // Include token in Authorization header if available
    },
  });

  // Interceptor to handle token changes (e.g., logout)
  api.interceptors.request.use(
    (config) => {
      config.headers.Authorization = token ? `Bearer ${token}` : '';
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  // Helper functions for making API requests
  const get = (url, params) => api.get(url, { params });
  const post = (url, data) => api.post(url, data);
  const put = (url, data) => api.put(url, data);
  const del = (url) => api.delete(url);

  return { get, post, put, del };
};

export default useApi;