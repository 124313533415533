import { useRef, useState, useEffect, useContext } from "react";
import "./login.css"
import useApi from "../../utils/api"
import { AuthContext } from '../../utils/AuthContext';
import { useNavigate, Navigate } from 'react-router-dom';
import { FaUser,FaLock } from 'react-icons/fa'

function Login() {
    const userRef = useRef();
    const errRef = useRef();

    const [user, setUser] = useState('');
    const [pwd, setPwd] = useState('');
    const [errMsg, setErrMsg] = useState('');
    const [sucsess, setSuccess] = useState(false);
    const { login } = useContext(AuthContext);

    const { post } = useApi('');
    const navigate = useNavigate();

    useEffect(() => {
        userRef.current.focus();
        return () => {
            // Cancel any ongoing asynchronous tasks or subscriptions here
            // For example, if `post` is a subscription, unsubscribe here
        };
    }, [])
    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await post(('/login'),
                JSON.stringify({ user, pwd })
            );
            console.log(JSON.stringify(response?.data));
            //console.log(JSON.stringify(response));
            const status = response?.data?.status;

            if (status === 1) {
                const accessToken = response?.data?.user?.user?.token;
                const points = response?.data?.user?.user?.points;
                const uid = response?.data?.user?.user?.uid;
                //const roles = response?.data?.roles;
                login(accessToken, points,uid);
                navigate('/');

            }

            //setAuth({ user, pwd, roles, accessToken });
            setUser('');
            setPwd('');

            //navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('שרת בתחזוקה');
            } else if (err.response?.status === 400) {
                setErrMsg('שם משתמש או סיסמה שגויים');
            } else if (err.response?.status === 401) {
                setErrMsg('לא מורשה');
            } else {
                setErrMsg('התחברות נכשלה');
            }
            errRef.current.focus();
        }
    }
    return (

        <div className="wraper">
            <p ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</p>

            <form onSubmit={handleSubmit}>
                <h1>התחברות</h1>
               
                <div className="input-box">
                    <input
                        type="text"
                        id="username"
                        placeholder="שם משתמש"
                        ref={userRef}
                        autoComplete="off"
                        onChange={(e) => setUser(e.target.value)}
                        value={user}
                        required
                    />
                    <FaUser className="icon"/>
                </div>
                
                <div className="input-box">
                    <input
                        type="password"
                        placeholder="סיסמה"
                        id="password"
                        onChange={(e) => setPwd(e.target.value)}
                        value={pwd}
                        required
                    />
                    <FaLock className="icon"/>
                </div>
                <button>התחבר</button>
            </form>
        </div>
    );
}

export default Login;
