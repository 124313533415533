import React, { useState, useEffect,useContext } from "react";
import MobileOnlyApp from './MobileOnlyApp';
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import { Home } from "./components/Home/Home";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer";
import Resume from "./components/Resume/ResumeNew";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import Cragil from "./components/Cragil/Cragil";
import Crav from "./components/Crav/Crav";
import Cshita from "./components/Cshita/Cshita";
import PrivateRoutes from "./utils/PrivateRoutes";
import Login from "./components/Login/Login";

import { AuthContext } from './utils/AuthContext';
import MyCard from "./components/MyCard/MyCard";
import History from "./components/History/History"
import Chistory from "./components/Chistory/Chistory";
function App() {

  const ajaxCalls = [];
  const { isLoggedIn } = useContext(AuthContext);
  const { userPoints } = useContext(AuthContext);
 

  const MOBILE_BREAKPOINT = 768;
  const environment = process.env.REACT_APP_ENVIRONMENT;
  // Check if the viewport width is less than the mobile breakpoint
  const isMobile = window.innerWidth < MOBILE_BREAKPOINT;

    return (
      <Router>
        <Preloader />
        <div className="App" id="scroll">
        {isLoggedIn && isMobile &&(
          <>
            <Navbar/>
            <ScrollToTop />
          </>
        )}
       
          <Routes>
          {!isMobile && <>(
                <Route path="*" element={<div><h1>Only Mobile</h1></div>} />)</>
          }
          {isMobile && (
            <>
            <Route element={<PrivateRoutes />}>
              <Route path="/" element={<Home ajaxc={ajaxCalls}/>} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="f-ragil" element={<Cragil />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="f-rav" element={<Crav />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="f-shita" element={<Cshita />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="myc" element={<MyCard />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="history" element={<History />} />
            </Route>
            <Route element={<PrivateRoutes />}>
              <Route path="chistory" element={<Chistory />} />
            </Route>
          

            <Route path="/login" element={<Login />} />
            <Route path="/project" element={<Projects />} />
            <Route path="/about" element={<About />} />
            <Route path="/resume" element={<Resume />} />
            <Route path="*" element={<Navigate to="/login" />} />
            </>
          )}
          </Routes>

        </div>
      </Router>
    )
}

export default App; 