import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";

import useApi from "../../utils/api"
import { useRef, useState, useEffect, useContext } from "react";
import { AuthContext } from '../../utils/AuthContext';
import { HiArrowCircleRight, HiArrowCircleLeft } from "react-icons/hi";
import "./Chistory.css"
import ic_calendar_white from "../../Assets/ic_calendar_white.svg";
import ic_card_clubs from "../../Assets/ic_card_clubs.png";
import ic_card_diamond from "../../Assets/ic_card_diamond.png";
import ic_card_hearth from "../../Assets/ic_card_hearth.png";
import ic_card_peak from "../../Assets/ic_card_peak.png";
import { fetchData } from '../../utils/tools';
function Chistory() {
    const { token, updatePoints, userID, logout } = useContext(AuthContext);
    const { post } = useApi(token);
    const [CardsH, setCardsH] = useState(null);


    useEffect(() => {

        const fetchDataFromAPI = async () => {
            try {
                //setIsLoaded(false);
                //const result = await fetchData(window.baseUrl + '/gethist');
                //const response = JSON.parse(result);
                const response = await post(('/gethist'),
                    JSON.stringify({ userID })
                );
                //const response = JSON.parse(result.data)
                //console.log(JSON.stringify(response?.data));
                if (response.data.success != 1) {
                    logout();
                } else {
                    setCardsH(response.data.hist);
                }

            } catch (error) {
                console.error('Error fetching view Chistory:', error);
                //setError(error.message);
                //setIsLoaded(true);
            }
        };

        fetchDataFromAPI();
        return () => {
        };
    }, []);

    const viewArr = {
        "07": "7",
        "08": "8",
        "09": "9",
        10: "10",
        11: "J",
        12: "Q",
        13: "K",
        14: "A"
    };
    return (
        <Container fluid className="project-section">
            <Particle />
            <Container>
                <h1 className="project-heading">
                    <strong className="purple">תוצאות הגרלות</strong>
                </h1>
                <div className="complex_stiky_titleh print" style={{ position: "sticky", top: 120, zIndex: 1 }} >
                    <div className="complex_line_block">

                        <div className="stiky_title_num w-hidden-main w-hidden-medium w-hidden-small">#</div>
                    </div>
                    <div className="complex_line_blockh timing">

                        <div className="stiky_title_iconh calendarh w-hidden-main w-hidden-medium w-hidden-small">
                            <img src={ic_calendar_white} alt="" />
                        </div>
                    </div>
                    <div className="complex_line_blockh cardh">
                        <div className="comlex_title_cardh">
                            <img src={ic_card_clubs} alt="" />
                        </div>
                    </div>
                    <div className="complex_line_block cardh">
                        <div className="comlex_title_cardh">
                            <img src={ic_card_diamond} alt="" />
                        </div>
                    </div>
                    <div className="complex_line_block cardh">
                        <div className="comlex_title_cardh">
                            <img src={ic_card_hearth} alt="" />
                        </div>
                    </div>
                    <div className="complex_line_block cardh">
                        <div className="comlex_title_cardh">
                            <img src={ic_card_peak} alt="" />
                        </div>
                    </div>


                </div>

                <div id="MinimizedRecordsDiv">
                    <ol className="w-list-unstyledh">

                        {CardsH?.map((card, index) => (
                            <li className="complex_line_itemh" key={index}>
                                <div className="complex_line_blockh" >
                                    <div>
                                        {card?.Chance_Lottery_Number}<br />
                                    </div>
                                </div>
                                <div className="complex_line_blockh timingh">
                                    <div>
                                        {card?.Chance_Date}</div>
                                    <div className="comlex_line_timeh" >
                                        {card?.Chance_Time}</div>
                                </div>
                                <div className="complex_line_blockh cardh" >
                                    <div>{viewArr[card?.Chance_Lottery_Number_4]}</div>
                                </div>
                                <div className="complex_line_blockh cardh" >
                                    <div>{viewArr[card?.Chance_Lottery_Number_3]}</div>
                                </div>
                                <div className="complex_line_blockh cardh">
                                    <div>{viewArr[card?.Chance_Lottery_Number_2]}</div>
                                </div>
                                <div className="complex_line_blockh cardh" >
                                    <div>{viewArr[card?.Chance_Lottery_Number_1]}</div>
                                </div>
                            </li>
                        ))}

                    </ol>
                </div>

            </Container>
        </Container>
    );
}

export default Chistory;
