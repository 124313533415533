import {React} from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { CgWebsite } from "react-icons/cg";
import { BsGithub } from "react-icons/bs";
import $ from 'jquery';

function Ccardtype(props) {
    let type_selection = -1;
    let c_type = 0;
    var TypeClike = function changeColor2(type,id){
        
        $('p[id^="t"]').removeClass("active");
        if(type_selection != -1){
            $("#t" + type_selection).removeClass("active");
        }
        type_selection = id;
        c_type = type;
        console.log('CardType Cilke:  ' +id + " -> " + type);
       
        $("#t" + id).addClass("active");
        props.onChange(type)
      };

    return (
        <Card className="project-card-view">
            <Card.Body>
                <Card.Title>{props.title}</Card.Title>
                <div className="cards-list">
                {
                props.typearr.map((types, indexs) => {
                    return(
                        types.map((type,index) =>
                    {
                        if(type == 5){
                            return (<p id={"t05"} onClick={() => TypeClike(type,indexs+""+index)} className={`cards-list__card cards-list-rav-chance ${props.CardType ==  type ? 'active' : ''}`}  >{"רב צ'אנס"}</p>);
                        }else{
                            return (<p id={"t"+indexs+""+index} onClick={() => TypeClike(type,indexs+""+index)} className={`cards-list__card ${props.CardType ==  type ? 'active' : ''}`}  >{type}</p>);
                        }
                    })
                    );
                })
                }
                </div>
            </Card.Body>
        </Card>
    );
}
export default Ccardtype;
