import React from "react";
import { Link } from 'react-router-dom';
import { Container, Row, Col } from "react-bootstrap";
import homeLogo from "../../Assets/home-main.svg";
import Particle from "../Particle";
import Home2 from "./Home2";
import Type from "./Type";
import { NextChanceCounter } from "../../include/NextChanceCounter"
import Timer2 from "../../include/Timer2"
export class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      resultsData: [],
      isLoaded: false,
      isError: false,
    };
    this.name = props.name;
    this.gameType = 3;
    this.ajaxc = props.ajaxc;
  }
  render() {
    return (
     
        <Container fluid className="home-section" id="home">
          <Container className="home-content">
            <NextChanceCounter gameType={this.gameType} myApp={this.ajaxc} />
            <Row>
              <Col md={2} >
                <div className="question-block question-block_small">
                  <div  className="home-chance-ball-icon">
                  </div>
                  <Link to="/f-ragil" className="question-block__name">צ'אנס רגיל</Link>
                  <button className="question-block__arrow-button" tabIndex="0">
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2}>
                <div className="question-block question-block_small">
                  <div className="home-chance-ball-icon">
                  </div>
                  <Link to="/f-rav" className="question-block__name">רב צ'אנס</Link>
                  <button className="question-block__arrow-button" tabIndex="0">
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2} >
                <div className="question-block question-block_small">
                  <div  className="home-chance-ball-icon">
                  </div>
                  <Link to="/f-shita" className="question-block__name">צ'אנס שיטתי</Link>
                  <button className="question-block__arrow-button" tabIndex="0">
                  </button>
                </div>
              </Col>
            </Row>
            <Row>
              <Col md={2} >
                <div className="question-block question-block_small">
                  <div  className="home-chance-ball-icon">
                  </div>
                  <Link to="/chistory" className="question-block__name">תוצאות הגרלות</Link>
                  <button className="question-block__arrow-button" tabIndex="0">
                  </button>
                </div>
              </Col>
            </Row>
          </Container>
        </Container>
    );
  }
}

//export default Home;
