import $ from 'jquery';
import React from 'react';

export var suppressToastShow = [false];

//==================================================================== AJAX CALLS ====================================================================================

export function SimpleAjax(myurl, callback, myApp, method = 'GET', postData = null,token=null, cacheTime = 120) {
    console.log(myurl);
    // Check if cached in session storage
    let jres;
    let sessionStorageValueTime = sessionStorage.getItem("Time-" + myurl);
    if (sessionStorageValueTime !== null) {
        let diff = new Date() - new Date(sessionStorageValueTime);
        if (diff / 1000 < cacheTime) {
            let cachedResponse = sessionStorage.getItem(myurl);
            if (cachedResponse !== null) {
                try {
                    let response = JSON.parse(cachedResponse);
                    console.log(response);
                    callback(response, false);
                } catch (e) {
                    console.log(e);
                    console.log(cachedResponse);
                    callback([], true);
                }

                clearNotUpdatedSessionStorage();
                return;
            }
        } else {
            sessionStorage.removeItem("Time-" + myurl);
            sessionStorage.removeItem(myurl);
        }
    }

    let ajaxOptions = {
        url: myurl,
        type: method,
        timeout: 5000,
        success: function (response) {
            myApp[myurl] = null;
            jres = JSON.stringify(response.next);
            jres = JSON.stringify(response);
            jres = fixJsonString(jres);
            sessionStorage.setItem(myurl, jres);
            sessionStorage.setItem("Time-" + myurl, new Date());

            try {
                //response = JSON.parse(response);
                //console.log(response);
                callback(response, false);
            } catch (e) {
                console.log(e);
                console.log(response);
                callback([], true);
            }

            clearNotUpdatedSessionStorage();
            return;
        },
        error: function (jqXHR, textStatus) {
            myApp[myurl] = null;
            console.error(textStatus);
            callback(textStatus, true);
        }
    };

    if (method === 'POST') {
        ajaxOptions.contentType = 'application/json';
        ajaxOptions.data = JSON.stringify(postData);
        ajaxOptions.headers = {
            'Authorization': `Bearer ${token}`
        };
    }

    let ajax = $.ajax(ajaxOptions);

    ajax.fail(function (jqXHR, textStatus) {
        myApp[myurl] = null;
        console.error(textStatus);
        callback(textStatus, true);
    });
    myApp[myurl] = ajax;
}


function clearNotUpdatedSessionStorage() {
    const cacheTime = 60;
    let keys = Object.keys(sessionStorage);
    keys.map(function (key) {
        if (key.startsWith("Time-")) {
            let timeStamp = sessionStorage.getItem(key);
            let diff = new Date() - new Date(timeStamp);
            if (diff / 1000 > cacheTime) {
                sessionStorage.removeItem(key.substring("Time-".length), key.length);
                sessionStorage.removeItem(key);
            }
        }
        return null;
    });
}


export function postDataToJson(postArray){
    let obj = {};
    for (let i = 0; i < postArray.length; i++) {
        obj[postArray[i].name] = postArray[i].value;
    }

    return obj;
}

export function fixJsonString(jsonString) {
    jsonString = jsonString.replace(/\\n/g, "\\n")
        .replace(/\\'/g, "\\'")
        .replace(/\\"/g, '\\"')
        .replace(/\\&/g, "\\&")
        .replace(/\\r/g, "\\r")
        .replace(/\\t/g, "\\t")
        .replace(/\\b/g, "\\b")
        .replace(/\\f/g, "\\f");

    return jsonString;
}

//===================================================================== VIDEO FUNCTIONS =====================================================================================





export function LoadIframVodPais(clipType, lotteryid) {
    console.log(clipType, lotteryid);
    let screenwidth = 320;
    let screenheight = 260;
    if (window.screen.width > 641) { screenwidth = 430; screenheight = 350 };

    //let iframePaisVodUrlOld ='https://vod.pais.co.il/1000-he/PAIS.aspx?type=vod&clipType=' + clipType + '&label=' + lotteryid + '&autoplay=fasle&width=' + screenwidth + '&height=' + screenheight;
    let iframePaisVodUrl ='https://pais.l7cdn.com/player/if?width=100%25&height=100%25&type=vod&clipType=' + clipType + '&autoplay=false&mute=false&label='+ lotteryid;

    return iframePaisVodUrl;
}

export function stopAllVideo(gametype)
{
    var iframeid="";
    if(gametype===1)
    {
        iframeid="myVodIframe_pais_loto";
    }
    else if(gametype===3)
    {
        iframeid="myVodIframe_pais_chance";
    }
    else if(gametype===4)
    {
        iframeid="myVodIframe_pais_123";
    }
    else if(gametype===5)
    {
        iframeid="myVodIframe_pais_777";
    }
    else if(gametype===6)
    {
        iframeid="myVodIframe_pais_minuim";
    }
    var iframe = document.getElementById(iframeid);
	if ( iframe ) {
		var iframeSrc = iframe.src;
		iframe.src = iframeSrc;
	}
 
}





//=================================================================== VALIDATORS ==========================================================================================

export function NameValidator(value) {
    const hebChars = ['א', 'ב', 'ג', 'ד', 'ה', 'ו', 'ז', 'ח', 'ט', 'י', 'כ', 'ל', 'מ', 'נ', 'ס', 'ע', 'פ', 'צ', 'ק', 'ר', 'ש', 'ת', 'ף', 'ץ', 'ן', 'ך', 'ם', '.', '-', '\'', '׳', "\"", '״',' '];
    value = value.trim();
    if (value.length < 2) return false;
    for (let i = 0; i < value.length; i++) {
        if (hebChars.indexOf(value.charAt(i)) < 0) {
            return false;
        }
    }
    return true;
}

export function phoneValidator(value) {
    value = value.trim();
    if (!isNumeric(value)) return false;
    if (value.length < 7 || value.length > 8) return false;
    return true;
}


export function emailValidator(value) {
    var re = /^(([^<>()/[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(value).toLowerCase());
}


export function isNumeric(value) {
    var regex = /^\d+$/;
    return regex.test(value);
}


export function idNumberValidator(idnum) {
    let idnum1, idnum2, idnum3, idnum4, idnum5, idnum6, idnum7, idnum8, idnum9
    idnum = idnum.trim();
    if (idnum.length === 0)
        return false;
    if (idnum.length > 9)
        return false;

    for (let i = 0; i < idnum.length; i++) {
        if (isNaN(idnum.substr(i, 1)))
            return false;
    }
    while (idnum.length < 9) {
        idnum = "0" + idnum;
    }
    if (idnum === "000000000")
        return false;

    idnum1 = idnum.substr(0, 1) * 1;
    idnum2 = idnum.substr(1, 1) * 2;
    idnum3 = idnum.substr(2, 1) * 1;
    idnum4 = idnum.substr(3, 1) * 2;
    idnum5 = idnum.substr(4, 1) * 1;
    idnum6 = idnum.substr(5, 1) * 2;
    idnum7 = idnum.substr(6, 1) * 1;
    idnum8 = idnum.substr(7, 1) * 2;
    idnum9 = idnum.substr(8, 1) * 1;

    if (idnum1 > 9) idnum1 = (idnum1 % 10) + 1;
    if (idnum2 > 9) idnum2 = (idnum2 % 10) + 1;
    if (idnum3 > 9) idnum3 = (idnum3 % 10) + 1;
    if (idnum4 > 9) idnum4 = (idnum4 % 10) + 1;
    if (idnum5 > 9) idnum5 = (idnum5 % 10) + 1;
    if (idnum6 > 9) idnum6 = (idnum6 % 10) + 1;
    if (idnum7 > 9) idnum7 = (idnum7 % 10) + 1;
    if (idnum8 > 9) idnum8 = (idnum8 % 10) + 1;
    if (idnum9 > 9) idnum9 = (idnum9 % 10) + 1;

    var sumval = idnum1 + idnum2 + idnum3 + idnum4 + idnum5 + idnum6 + idnum7 + idnum8 + idnum9;

    sumval = sumval % 10
    if (sumval > 0) {
        return false;
    }

    return true;
}



// ================================================================ REUSED FUNCTIONS ==========================================================================
export function amountToHebrewString(amount) {
    if (amount > 1000000) {
        return amount / 1000000 + ' מיליון'
    }
    else if (amount === 1000000) {
        return ' מיליון'
    }
    else {
        amount = (amount).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
        return amount.split(".")[0];
    }
}



let prevClassName;
let className;





export function NumberFormat(number, decimalPoint) {
    number = parseInt(number);
    if (decimalPoint) {
        number = number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
    }
    else {
        number = number.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,').split(".")[0];
    }

    return number;

}

export function ToChacneCard(cardNumber) {
    if (cardNumber === "11" || cardNumber === 11) return "J";
    else if (cardNumber === "12" || cardNumber === 12) return "Q";
    else if (cardNumber === "13" || cardNumber === 13) return "K";
    else if (cardNumber === "14" || cardNumber === 14) return "A"
    else return parseInt(cardNumber);
}



export function getCardHebrewName(type) {
    if (type === "peak") {
        return " עלה";
    }
    else if (type === "heart") {
        return " לב";
    }
    else if (type === "diamond") {
        return " יהלום";
    }
    else if (type === "clubs") {
        return " תלתן";
    }
}


 export function isNewerVersion (oldVer, newVer) {
    const oldParts = oldVer.split('.')
    const newParts = newVer.split('.')
    for (var i = 0; i < newParts.length; i++) {
      const a = parseInt(newParts[i]) || 0
      const b = parseInt(oldParts[i]) || 0
      if (a > b) return true
      if (a < b) return false
    }
    return false
  }


//======================================================= INSPECTION SAVE AND LOAD ==============================================================================

export function SaveData(gameType, formType, numOfInputs, data, numOfStrongInputs, savedFormId) {
    debugger;
    let storedForms = localStorage.getItem("Saved_Numbers");
    let newDataId;
    //No data is saved -> save first data
    if (storedForms === null || storedForms === '[]') {
        localStorage.setItem("Saved_Numbers", "[]");
        storedForms = [];
        newDataId = 1;
    }
    //Found data -> parse it
    else {
        storedForms = JSON.parse(storedForms);
        newDataId = storedForms[storedForms.length - 1].id + 1;
    }

    if (savedFormId) {
        newDataId = savedFormId;
        storedForms.pop(x => x.id === savedFormId);
    }

    let newData = {};
    newData.id = newDataId;
    newData.gameType = gameType;
    newData.formType = formType;
    newData.numOfInputs = numOfInputs;                                              //total num of inputs in lotto or num of inputs per line in chance
    newData.numOfStrongInputs = numOfStrongInputs ? numOfStrongInputs : 0;          //strong number in lotto or num of lines in chance
    newData.tables = data;

    storedForms.push(newData);
    localStorage.setItem("Saved_Numbers", JSON.stringify(storedForms));
}


export function LoadData(gameType) {
    let storedForms = localStorage.getItem("Saved_Numbers");
    let filteredForms = [];

    if (!storedForms || storedForms === null || storedForms === '[]') {
        return null;
    }

    storedForms = JSON.parse(storedForms);
    filteredForms = storedForms.filter(x => {
        if (x.gameType === gameType) return x;
        else return null;
    });

    if (filteredForms.length === 0) {
        return null;
    }

    console.log(filteredForms);
    return filteredForms;
}


export function RemoveData(removeId) {
    let storedForms = localStorage.getItem("Saved_Numbers");
    if (!storedForms || !removeId) return false;
    let indexToDelete = -1;

    storedForms = JSON.parse(storedForms);
    for (let index = 0; index < storedForms.length; index++) {
        const element = storedForms[index];
        if (element.id === removeId){
            indexToDelete = index;
        }      
    }

    if (indexToDelete > -1){
        storedForms.splice(indexToDelete, 1);
    }
    
    localStorage.setItem("Saved_Numbers", JSON.stringify(storedForms));

    return true;
}


export function GetCountOfAppearances(array, inp) {
    let count = 0;
    for (let index = 0; index < array.length; index++) {
        if (array[index] === inp) {
            count++;
        }
    }

    return count;
}



export function validateNumbers(currentRow, currentInput, isStrong, min, max, nextInput, myParent, allowDuplicates = false) {
    let INPUT_LENGTH_FOR_AUTO_FOCUS = (myParent.state.gameType === 4 || myParent.state.gameType === 9) ? 1 : 2;
    let arr = isStrong ? myParent.strongInputs[currentRow] : myParent.regularInputs[currentRow];
    let inputType = isStrong ? "strong" : "number";
    let inputName = 'tbl-' + currentRow + '_' + inputType + '-' + currentInput;
    let input = $('#' + inputName).val();
    let error = false;
    let lengthOk = false;

    //Input was deleted
    if ($.isNumeric(input)) {
        //Handle extra and 123
        if (INPUT_LENGTH_FOR_AUTO_FOCUS === 1){
            input = parseInt(input).toString();
        }

        //Hadnle all games
        lengthOk = input.length === INPUT_LENGTH_FOR_AUTO_FOCUS ? true : false;
        input = parseInt(input);
        $('#' + inputName).val(input);
    }
    else if (input === '') {
        input = -1;
    }
    else {
        error = true;
    }

    //Out of bounds
    if (input < min || input > max) {
        error = true;
    }

    //Already exists
    if (arr.indexOf(input) > -1 && myParent.state.gameType !== 4 && !allowDuplicates) {
        error = true;
    }

    //Add number and Save the selection in the parent array
    arr[currentInput] = input;
    if (isStrong) {
        myParent.strongInputs[currentRow] = arr;
    }
    else {
        myParent.regularInputs[currentRow] = arr;
    }



    //Mark all duplicates in red
    if (myParent.state.gameType !== 4) {
        const numOfInputs = isStrong ? myParent.state.numOfStrongInputs : myParent.state.numOfRegularInputs;
        for (let i = 0; i < numOfInputs; i++) {
            if (allowDuplicates) break;
            let currentInput = $('#tbl-' + currentRow + '_' + inputType + '-' + (i + 1)).val();
            currentInput = parseInt(currentInput);
            if (GetCountOfAppearances(arr, currentInput) > 1) {
                toggleError(true, 'tbl-' + currentRow + '_' + inputType + '-' + (i + 1));
            }
            else {
                toggleError(false, 'tbl-' + currentRow + '_' + inputType + '-' + (i + 1));
            }
        }
    }
    
    if (!error) {
        if (lengthOk) {
            $('#' + nextInput).removeAttr("disabled");
            $('#' + nextInput).focus();            
        }
    }

    toggleError(error, inputName);

}

export function validateNumbersExtra(currentRow, currentInput, isStrong, min, max, nextInput, myParent, allowDuplicates = false) {
    let INPUT_LENGTH_FOR_AUTO_FOCUS = (myParent.state.gameType === 4 || myParent.state.gameType === 9) ? 1 : 2;
    let arr = isStrong ? myParent.strongInputs[currentRow] : myParent.regularInputs[currentRow];
    let inputType = isStrong ? "strong" : "number";
    let inputName = 'tblx-' + currentRow + '_' + inputType + '-' + currentInput;
    let input = $('#' + inputName).val();
    let error = false;
    let lengthOk = false;

    //Input was deleted
    if ($.isNumeric(input)) {
        //Handle extra and 123
        if (INPUT_LENGTH_FOR_AUTO_FOCUS === 1){
            input = parseInt(input).toString();
        }

        //Hadnle all games
        lengthOk = input.length === INPUT_LENGTH_FOR_AUTO_FOCUS ? true : false;
        input = parseInt(input);
        $('#' + inputName).val(input);
    }
    else if (input === '') {
        input = -1;
    }
    else {
        error = true;
    }

    //Out of bounds
    if (input < min || input > max) {
        error = true;
    }

    //Already exists
    if (arr.indexOf(input) > -1 && myParent.state.gameType !== 4 && !allowDuplicates) {
        error = true;
    }

    //Add number and Save the selection in the parent array
    arr[currentInput] = input;
    if (isStrong) {
        myParent.strongInputs[currentRow] = arr;
    }
    else {
        myParent.regularInputs[currentRow] = arr;
    }



    //Mark all duplicates in red
    if (myParent.state.gameType !== 4) {
        const numOfInputs = isStrong ? myParent.state.numOfStrongInputs : myParent.state.numOfRegularInputs;
        for (let i = 0; i < numOfInputs; i++) {
            if (allowDuplicates) break;
            let currentInput = $('#tbl-' + currentRow + '_' + inputType + '-' + (i + 1)).val();
            currentInput = parseInt(currentInput);
            if (GetCountOfAppearances(arr, currentInput) > 1) {
                toggleError(true, 'tbl-' + currentRow + '_' + inputType + '-' + (i + 1));
            }
            else {
                toggleError(false, 'tbl-' + currentRow + '_' + inputType + '-' + (i + 1));
            }
        }
    }
    
    if (!error) {
        if (lengthOk) {
            $('#' + nextInput).removeAttr("disabled");
            $('#' + nextInput).focus();            
        }
    }

    toggleError(error, inputName);

}

export function logEvent(my_event,params)
{  
   try {
             
     
                   
     // console.log(window.cordova.platformId, pageName)
     if (window.cordova){
    //    if (window.cordova.platformId === "android") {
         if (params===undefined || params===null) window.cordova.plugins.firebase.analytics.logEvent(my_event);
         else window.cordova.plugins.firebase.analytics.logEvent(my_event,params);
         
    //    }
    //    else if (window.cordova.platformId === "ios"){
    //     // window.cordova.plugins.firebase.analytics.logEventIOS(pageName);
    //     if (params===undefined || params===null)
    //       window.cordova.plugins.firebase.analytics.logEventIOS([my_event]);
    //      else
    //      { 
    //         window.cordova.plugins.firebase.analytics.logEventIOS([my_event,params]);
    //      }   
    //    }
     }
   } catch (e) {
     console.log(e);
     alert(e);
   }
   
}

function toggleError(isError, inputName) {
    if (isError) {
        $('#' + inputName).css('color', 'red');
    }
    else {
        $('#' + inputName).css('color', 'black');
    }
}